<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>
          <div class="mb-1">
            {{ $t('conta.credito.title') }}
          </div>
          <h3
            v-if="resumo"
            class="mb-1 font-weight-bolder"
          >
            $ {{ resumo.saldo.toFixed(2) }}
          </h3>
        </b-card-title>
      </b-card-header>
    </b-card>
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('indicacao.emptyTextTable')"
        :sort-desc.sync="isSortDirDesc"
        striped
      >
        <template #cell(dataCriacao)="data">
          {{ formatDateTimeBR(data.value) }}
        </template>
        <template #cell(tipo)="data">
          {{ $t(data.value) }}
        </template>
        <template #cell(produto)="data">
          {{ data.value }}
        </template>
        <template #cell(valor)="data">
          <span :class="['font-weight-bolder', getValorStyle(data.item.tipo)]">{{ formatCurrencyUSD(data.value, 6) }}</span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('indicacao.extrato.alertTextMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BRow, BCol, BCardHeader, BCardTitle,

} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeBR, formatDateBR, formatCurrencyUSD } from '@/utils/filter'
import extratoStoreModule from './extratoStoreModule'

const CONTA_EXTRATO_STORE_MODULE_NAME = 'conta-extrato'

export default {

  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatCurrencyUSD,
      formatDateBR,
      formatDateTimeBR,
      loadingOverlayGrid: false,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'dataCriacao,desc',
      isSortDirDesc: true,
      dataList: null,
      resumo: null,
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'dataCriacao', label: this.$i18n.t('conta.credito.datacriacao') },
        { key: 'tipo', label: this.$i18n.t('conta.credito.tipo') },
        { key: 'produto', label: this.$i18n.t('conta.credito.produto') },
        { key: 'valor', label: this.$i18n.t('conta.credito.valor') },
      ]
    },
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(CONTA_EXTRATO_STORE_MODULE_NAME)) store.registerModule(CONTA_EXTRATO_STORE_MODULE_NAME, extratoStoreModule)
    this.fetchList()
    this.getExtratoResumo()
  },

  beforeDestroy() {
    if (store.hasModule(CONTA_EXTRATO_STORE_MODULE_NAME)) store.unregisterModule(CONTA_EXTRATO_STORE_MODULE_NAME)
  },

  methods: {

    getValorStyle(tipo) {
      if (tipo === 'DEPOSITO') return 'text-success'
      if (tipo === 'RETIRADA') return 'text-danger'
      if (tipo === 'TAXA') return 'text-danger'
      if (tipo === 'TAXA_TRADER') return 'text-danger'
      return ''
    },

    fetchList() {
      store
        .dispatch('conta-extrato/getExtrato', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
    },

    getExtratoResumo() {
      store
        .dispatch('conta-extrato/getExtratoResumo')
        .then(response => {
          this.resumo = response.data
        })
    },
  },
}
</script>
